import { useState, useEffect, useRef } from 'react';
import { FaPhoneVolume } from "react-icons/fa6";

import './header.scss';
import Logo from '../../sources/logo.png';
import BackgroundVideo from '../../sources/bg.mp4';
import Frame from '../../sources/bg.png';
import checkIcon from '../../sources/check.png';

function Header() {

    const [isScroll, setIsScroll] = useState(false);
    const burgerNavRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setIsScroll(true);
            } else {
                setIsScroll(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        
        return (() => {
            window.removeEventListener('scroll', handleScroll);
        });

    }, []);

    const handleOpenNav = () => {
        burgerNavRef.current.classList.toggle('hide');
        setIsScroll(true);
    }
    
    return (
        <>
            <nav className={isScroll ? 'scrolled' : ''}>
                <div className="container">
                    
                    <div className='nav-burger' onClick={handleOpenNav}>
                        <a href="#home" onClick={(e) => e.stopPropagation()}><img src={Logo} alt="Логотип" /></a>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <a href="#contacts" className='call-btn' onClick={(e) => e.stopPropagation()}><FaPhoneVolume color="black" /></a>
                        <ul className='nav-burger__list hide' ref={burgerNavRef}>
                            <li><a href="#price">Ціни</a></li>
                            <li><a href="#gallery">Галерея</a></li>
                            <li><a href="#reviews">Відгуки</a></li>
                            <li><a href="#faq">Довідка</a></li>
                            <li><a href="#contacts">Контакти</a></li>
                        </ul>
                    </div>
                    <ul className='nav'>
                        <li><a href="#home"><img src={Logo} alt="Логотип" /></a></li>
                        <li><a href="#price">Ціни</a></li>
                        <li><a href="#gallery">Галерея</a></li>
                        <li><a href="#reviews">Відгуки</a></li>
                        <li><a href="#faq">Довідка</a></li>
                        <li><a href="#contacts">Контакти</a></li>
                    </ul>
                </div>
            </nav>

            <header id="home">
                <h1>Автомийка та детейлінг "2К" у місті Одеса</h1>
                <div className="header-bg">
                    <video playsInline webkit-playsinline="true" autoPlay loop muted>
                        <source src={BackgroundVideo} type="video/mp4" />
                    </video>
                        <div className="header-frame">
                            <img src={Frame} alt="Рамка" />
                        </div>
                </div>
                <div className="container">
                    <div className="header-body">
                        <div>
                            <p className='uppercase'>Подаруйте своєму автомобілю розкішний догляд, на який він заслуговує!</p>
                            <p>Чому обирають саме нас:</p>
                            <ul className='short-advantages'>
                                <li>Професійний та дбайливий підхід до кожної машини.</li>
                                <li>Сучасне обладнання та безпечні миючі засоби.</li>
                                <li>Широкий спекр послуг: від простого миття до повної хімчистки салону.</li>
                            </ul>
                            <div className="short-services uppercase">
                                <p>Наші послуги:</p>
                                <ul>
                                    <li><img src={checkIcon} alt="Галочка" />Детейлінг мийка</li>
                                    <li><img src={checkIcon} alt="Галочка" />Професійне полірування</li>
                                    <li><img src={checkIcon} alt="Галочка" />Хімчистка салону</li>
                                    <li><img src={checkIcon} alt="Галочка" />Реставрація шкіри</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

export default Header;