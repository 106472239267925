import { useState, useEffect } from 'react';
import useDB from '../../../services/useDB';

function Reviews() {
    const [reviews, setReviews] = useState([]);
    const { makeQuery } = useDB();

    useEffect(() => {
        makeQuery('reviews/').then(data => setReviews(data));
    //eslint-disable-next-line
    }, []);

    const [form, setForm] = useState({
        name: '',
        date: '',
        feedback: ''
    });

    const { create, generateID } = useDB();

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setForm(prevForm => ({
            ...prevForm,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setReviews(prevReviews => [...prevReviews, { ...form}]);
        const id = generateID();

        await create(`reviews/${id}`, form).then(() => alert('Дякуємо за ваш відгук!'));

        setForm({
            name: '',
            date: '',
            feedback: ''
        });
    };

    return (
        <section className="container" id="reviews">
            <h2>Відгуки</h2>
            <div className="reviews-container">
                <div className="reviews-wrapper">
                    {reviews.map((review, index) => (
                        <div className="reviews-block" key={index}>
                            <div className="reviews-block__profile">
                                <div>
                                    <p>{review.name}</p>
                                    <span>{review.date}</span>
                                </div>
                            </div>
                            <p>{review.feedback}</p>
                        </div>
                    ))}
                </div>
                
            <div className="reviews-form">
                <h3>Залиште ваш відгук</h3>
                <form onSubmit={handleSubmit}>
                    <div>
                        <label htmlFor="name">Ім'я</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            value={form.name}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="date">Дата відвідування</label>
                        <input
                            type="date"
                            id="date"
                            name="date"
                            value={form.date}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <div>
                        <label htmlFor="feedback">Відгук</label>
                        <textarea
                            id="feedback"
                            name="feedback"
                            value={form.feedback}
                            onChange={handleInputChange}
                            required
                        />
                    </div>
                    <button type="submit">Відправити</button>
                </form>
            </div>
            </div>

        </section>
    );
}

export default Reviews;
