import Gallery from './Gallery/Gallery';
import Reviews from './Reviews/Reviews';
import Faq from './Faq/Faq';
import PriceList from './PriceList/PriceList';

import './main.scss';

function Main() {
    return (
        <main>
            <PriceList />
            <Gallery />
            <Reviews />
            <Faq />
        </main>
    )
}

export default Main;