import { useState } from "react";
import { initializeApp } from "firebase/app";
import { getStorage } from 'firebase/storage';
import { 
    getFirestore, 
    doc, 
    setDoc, 
    updateDoc, 
    getDoc, 
    getDocs, 
    query, 
    collection,
    where,
} from "firebase/firestore";

import { getAuth } from "firebase/auth";
import { getDownloadURL, ref, listAll } from 'firebase/storage';

const firebaseConfig = {
    apiKey: "AIzaSyDkcVjVNZSi5aCBBE23xgPArcK1zTJIZnc",
    authDomain: "carwash-a9794.firebaseapp.com",
    projectId: "carwash-a9794",
    storageBucket: "carwash-a9794.appspot.com",
    messagingSenderId: "493025079146",
    appId: "1:493025079146:web:161ef0210fd3aafa72ff13",
    measurementId: "G-TQT8BQ9NX0"
  };

const app = initializeApp(firebaseConfig); 
const storage = getStorage(app);
const db = getFirestore(app);

const auth = getAuth();

export default function useDB() {
    const [isLoading, setIsLoading] = useState(false);

    async function get(url) {
        let data;
        let path = doc(db, url);
        setIsLoading(true);
        try {
            const response = await getDoc(path)
            if (response.exists()) {
                data = response.data();
            }
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
        return data;
    }

    async function create(url, data) {
        setIsLoading(true);
        let path = doc(db, url);
        try {
            await setDoc(path, data);
        } catch(e) {
        } finally {
            setIsLoading(false);
        }
    }

    async function update(url, data) {
        setIsLoading(true);
        let path = doc(db, url);
        try {
            await updateDoc(path, data);
        } catch(e) {
        } finally {
            setIsLoading(false);
        }
    }

    async function makeQuery(url, id, customName = 'id') {
        let customQuery;
        if (id) {
            customQuery = query(collection(db, url), where(customName, "==", id));
        } else {
            customQuery = query(collection(db, url));
        }
        let querySnapshot;

        try {
            setIsLoading(true);
            querySnapshot = await getDocs(customQuery);
        } catch(e) {
        } finally {
            setIsLoading(false);
        }
        let data = [];
        querySnapshot.forEach((snap) => data.push(snap.data()));
        return data;
    }

    async function getUrls() {
        try {
            const listRef = ref(storage, '/');
            const res = await listAll(listRef);

            const urlPromises = res.items.map(item => getDownloadURL(item));
    
            const urls = await Promise.all(urlPromises);
    
            return urls;
        } catch (error) {
            return [];
        }
    }

    function generateID(length = 20) {
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let result = '';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

    return {get, create, update, makeQuery, getUrls, auth, isLoading, generateID};
}