import Header from '../header/Header';
import Main from '../main/Main';
import Footer from '../footer/Footer';

function App() {
  return (
    <>
      <Header />
      <Main />
      <Footer />
    </>
  );
}

export default App;
