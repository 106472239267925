import { useState, useEffect } from 'react';

import useDB from '../../../services/useDB';

import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Gallery() {
    const [videoLinksStable, setVideoLinksStable] = useState();
    const { getUrls } = useDB();

    useEffect(() => {
        async function get () {
           await getUrls().then(urls => setVideoLinksStable(urls));
        }
        get();
    //eslint-disable-next-line
    }, []);

        const settings = {
            dots: true,
            infinite: true,
            slidesToShow: 3,
            slidesToScroll: 3,
            swipeToSlide: true,
            centerPadding: "60px",
            responsive: [
                {
                  breakpoint: 1030,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: true,
                    dots: true
                  }
                },
                {
                  breakpoint: 480,
                  settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: true
                  }
                }
            ]
        };

    
    return (
        <section className="container" id="gallery">
            <h2>Галерея</h2>
            <div className="gallery-wrapper">
                <div className="gallery-container">
                    <Slider {...settings}>
                        {videoLinksStable && videoLinksStable.map((video, index) => (
                        <div key={index}>
                            <video playsInline autoPlay={false} loop muted controls>
                                <source src={video} type="video/mp4" />
                            </video>
                        </div>
                        ))}
                    </Slider>
                </div>
            </div>
            <div className="simple-gallery hide">
                {videoLinksStable && videoLinksStable.map((video, index) => (
                <div key={index}>
                    <video playsInline autoPlay={true} loop muted controls>
                        <source src={video} type="video/mp4" />
                    </video>
                </div>
                ))}
            </div>
        </section>
    )
}

export default Gallery;
