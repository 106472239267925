import React, { useState, useEffect } from 'react';
import './faq.scss';

import useDB from '../../../services/useDB';

function Faq() {
    const [activeIndex, setActiveIndex] = useState(null);
    const [faqData, setFaqData] = useState([]);
    const handleQuestionClick = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const { makeQuery } = useDB();

    useEffect(() => {
        makeQuery('faq/').then(data => setFaqData(data));
        //eslint-disable-next-line
    }, []);

    return (
        <section className="container" id="faq">
            <h2>Довідка</h2>
            <ul className="faq-list">
                {faqData.map((item, index) => (
                    <li key={index} className="faq-item">
                        <button className="faq-question" onClick={() => handleQuestionClick(index)}>
                            {item.question}
                        </button>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                {item.answer}
                            </div>
                        )}
                    </li>
                ))}
            </ul>
        </section>
    );
}

export default Faq;
