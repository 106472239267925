import { Fragment, useState, useEffect } from 'react';

import useDB from '../../../services/useDB';

import './priceList.scss';

import smallCar from '../../../sources/pricelist/small-car.png';
import mediumCar from '../../../sources/pricelist/medium-car.png';
import jeepCar from '../../../sources/pricelist/jeep-car.png';
import bus from '../../../sources/pricelist/bus.png';
import firstBg from '../../../sources/pricelist/first-bg.png';
import secondBg from '../../../sources/pricelist/second-bg.png';

function PriceList() {

    const [firstTableData, setFirstTableData] = useState([]);
    const [secondTableData, setSecondTableData] = useState([]);

    const { makeQuery } = useDB();

    useEffect(() => {
        makeQuery('firstTable/').then(data => setFirstTableData(data));
        makeQuery('secondTable/').then(data => setSecondTableData(data));
        //eslint-disable-next-line
    }, []);

    const formatDescription = (description) => {
        return description.split('**').map((line, index) => (
            <Fragment key={index}>
                {line}
                <br />
            </Fragment>
        ));
    }
    
    const generateTable = (tableData, isFirstTable = true) => {
        return (
            <table className={isFirstTable ? 'first-table' : 'second-table'}>
                <thead>
                    <tr>
                        <td colSpan={2}>{!isFirstTable && 'Додаткові послуги'}</td>
                        <td>
                            <img src={smallCar} alt="" />
                            <p>A</p>
                        </td>
                        <td>
                            <img src={mediumCar} alt="" />
                            <p>B</p>
                        </td>
                        <td>
                            <img src={jeepCar} alt="" />
                            <p>С</p>
                        </td>
                        <td>
                            <img src={bus} alt="" />
                            <p>D</p>
                        </td>
                    </tr>
                </thead>
                <br />
                <tbody>
                    {tableData.map((row, index) => (
                        <Fragment key={index}>
                            <tr>
                                <th><p>{row.service}</p></th>
                                <td className='list'>
                                    <p>{isFirstTable ? formatDescription(row.description) : row.description}</p>
                                </td>
                                {row.prices.map((price, priceIndex) => (
                                    <td className='price' key={priceIndex}>{price}</td>
                                ))}
                            </tr>
                            <br />
                        </Fragment>
                    ))}
                </tbody>
            </table>
        );
    }

    return (
        <section className="container" id="price">
            <div className='price-block'>
                <img className="price-bg" src={firstBg} alt="" />
                <h2 className="uppercase"><span>2K</span> автомийка</h2>
                <div className="flex-center">
                    {generateTable(firstTableData, true)}
                </div>
            </div>

            <div className="price-block">
                <img className="price-bg" src={secondBg} alt="" />
                <h2 className="uppercase"><span>2K</span> автомийка</h2>
                <div className="flex-center">
                    {generateTable(secondTableData, false)}
                </div>
            </div>
        </section>
    );
}

export default PriceList;
